import isDemo from "../utilities/isDemo";
function getRedirectUrl(configData, messageJson) {
    var _a, _b, _c;
    var redirectUrl;
    if (isDemo) {
        redirectUrl = (_c = (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.demo) === null || _b === void 0 ? void 0 : _b.redirectUrl) !== null && _c !== void 0 ? _c : "";
    }
    else {
        redirectUrl = getProjectRedirectUrl(configData, messageJson);
    }
    return redirectUrl;
}
function getProjectRedirectUrl(configData, messageJson) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var redirectUrl = "";
    if (messageJson === null || messageJson === void 0 ? void 0 : messageJson.params) {
        if (messageJson.params.status === "completed") {
            redirectUrl = (_c = (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.redirect) === null || _b === void 0 ? void 0 : _b.success) !== null && _c !== void 0 ? _c : "";
        }
        else if (messageJson.params.status === "error_user") {
            redirectUrl = (_f = (_e = (_d = configData.content) === null || _d === void 0 ? void 0 : _d.redirect) === null || _e === void 0 ? void 0 : _e.userError) !== null && _f !== void 0 ? _f : "";
        }
        else if (messageJson.params.status === "error_tech") {
            redirectUrl = (_j = (_h = (_g = configData.content) === null || _g === void 0 ? void 0 : _g.redirect) === null || _h === void 0 ? void 0 : _h.technicalError) !== null && _j !== void 0 ? _j : "";
        }
    }
    return redirectUrl;
}
export default getRedirectUrl;
