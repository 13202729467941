import { detect } from "detect-browser";
export var System = {
    Browser: function () {
        return detect();
    },
    IsBrowserSupported: function () {
        var browser = detect();
        var version = this.browserVersion(browser);
        return ((((browser === null || browser === void 0 ? void 0 : browser.name) === "chrome" && version >= 76) ||
            ((browser === null || browser === void 0 ? void 0 : browser.name) === "edge-chromium" && version >= 76) ||
            ((browser === null || browser === void 0 ? void 0 : browser.name) === "crios" && version >= 76) ||
            ((browser === null || browser === void 0 ? void 0 : browser.name) === "safari" && version >= 12) ||
            ((browser === null || browser === void 0 ? void 0 : browser.name) === "ios" && version >= 12) ||
            ((browser === null || browser === void 0 ? void 0 : browser.name) === "firefox" && version >= 76)) &&
            this.IsWebRTCAvailable());
    },
    IsWebRTCAvailable: function () {
        return (window.RTCPeerConnection !== undefined &&
            window.RTCDataChannel !== undefined);
    },
    browserVersion: function (browser) {
        var version = 0;
        if (browser === null || browser === void 0 ? void 0 : browser.version) {
            version = Number.parseInt(browser.version.slice(0, Math.max(0, browser.version.indexOf("."))));
        }
        return version;
    },
    getBrowserData: function () {
        var browser = detect();
        var version = this.browserVersion(browser);
        return ("rtc: " +
            this.IsWebRTCAvailable() +
            "; browser: " +
            (browser === null || browser === void 0 ? void 0 : browser.name) +
            " " +
            version +
            "; isWebview: " +
            this.isWebview());
    },
    isWebview: function () {
        if (typeof window === "undefined") {
            return false;
        }
        var navigator = window.navigator;
        // @ts-expect-error: Property 'standalone' does not exist on type 'Navigator'.
        var standalone = navigator.standalone;
        var userAgent = navigator.userAgent.toLowerCase();
        var safari = userAgent.includes("safari");
        var ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
        var iosIpadWebview = ios && !safari;
        return ios
            ? (!standalone && !safari) || iosIpadWebview
            : userAgent.includes("wv");
    },
};
